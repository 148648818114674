'use client';
import { ELLink } from '@davincihealthcare/elty-design-system-react';
import Link from 'next/link';
import { ComponentPropsWithoutRef } from 'react';

type NavLinkProps = { href: string } & ComponentPropsWithoutRef<typeof ELLink>;

const NavLink = ({ href, ...rest }: NavLinkProps) => (
  <Link href={href} legacyBehavior>
    <ELLink href={href} {...rest} />
  </Link>
);

export default NavLink;
